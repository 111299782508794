import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  flex-direction: column;

  cursor: pointer;

  border: 0;
  border-radius: 0;

  background-color: #FFE4FB;

  width: 30vw;

  margin-right: 16px;
  margin-bottom: 16px;

  padding: 16px;

  > h1 {
    color: #444;
    font-size: 48px;
    margin-bottom: 16px;
  }

  > p {
    color: #666;
    font-weight: 500;
    font-size: 24px;
  }

  @media (max-width: 730px) {
    min-width: 90vw;
  }
`;

export const CourseInfos = styled.div`
  display: flex;
  justify-content: space-between;
  /* justify-self: flex-end; */

  margin-top: auto;
  padding-top: 32px;

  > div:not(:first-child) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      font-size: 16px;
      color: #553285;
      text-align: right;
    }

    h4 {
      font-size: 22px;
      color: #666;
    }

    h5 {
      margin-top: auto;
      font-size: 24px;
      color: #666;
    }
  }
`;

export const LeftInfos = styled.div`
  div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      color: #553285
    }

    p {
      color: #666;
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
