import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: -135% 0%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #FFE4FB;

  min-height: 38vh;
  width: 30vw;

  margin-right: 16px;
  margin-bottom: 16px;

  padding: 16px;

  > p {
    height: 32px;
    width: 100%;
  }

  .shimmer-effect {
    background-image: linear-gradient(
      -90deg,
      #E5C3E0 0%,
      #ECC7E6 100%
    );
    background-size: 400% 400%;
    animation: ${shimmerAnimation} 1.2s ease-in-out infinite;
  }

  @media (max-width: 730px) {
    min-width: 90vw;
  }
`;

export const Title = styled.div`
    height: 56px;
    width: 40%;

    margin-bottom: 32px;
`;

export const CourseInfos = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: auto;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

export const LeftInfos = styled.div`
  height: 56px;
  width: 30%;
`;

export const RightInfos = styled.div`
  width: 50%;
  height: 56px;
`;
