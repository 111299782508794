export const programmingCourseList = [
  {
    name: 'Python',
    checked: false,
  },
  {
    name: 'Javascript',
    checked: false,
  },
  {
    name: 'HTML e CSS',
    checked: false,
  },
  {
    name: 'PHP',
    checked: false,
  },
  {
    name: 'Java',
    checked: false,
  },
  {
    name: 'VBA',
    checked: false,
  },
];

export const languageCourseList = [
  {
    name: 'Inglês',
    checked: false,
  },
  {
    name: 'Espanhol',
    checked: false,
  },
];

export const investmentCourseList = [
  {
    name: 'Valuation',
    checked: false,
  },
  {
    name: 'Renda Fixa',
    checked: false,
  },
];
