import styled, { keyframes, css } from 'styled-components';

import DashBackground from '../../assets/dashboard-background.png';

export const Container = styled.div``;

export const Header = styled.div`
  height: 60vh;

  background-color: #553285;

  display: flex;
  align-items: center;
`;

const apperFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-25px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const SearchBarContainer = styled.div`
  margin-bottom: 100px;
  padding: 0 50px;

  animation: ${apperFromTop} 1.5s;

  flex: 1;
  width: 100%;

  h1 {
    font-size: 80px;
    text-align: center;
    margin-bottom: 32px;
    color: #fff;
  }
`;

export const SearchBar = styled.div`
  flex: 1;

  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 1120px;
  border-radius: 4px;

  margin: 0 auto;

  height: 60px;

  input {
    flex: 1;
    border: 0;

    height: 60px;

    padding: 0 16px;

    @media (max-width: 730px) {
      font-size: 14px;
    }
  }

  button {
    background: 0;
    border: 0;

    svg {
      margin: 0 16px;
      transition: 0.4s;
      color: #c3c3c3;
    }

    svg:hover {
      color: black;
    }
  }
`;

const spinIconAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

interface FilterContainerProps {
  spinIcon: boolean;
}

export const FilterContainer = styled.button<FilterContainerProps>`
  display: flex;
  align-items: center;

  flex: 1;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;

  background: 0;
  border: 0;

  justify-content: flex-end;

  margin-top: 16px;

  p {
    color: white;
    font-size: 20px;
  }

   svg {
    color: white;
    margin-right: 8px;
    ${(props) => (props.spinIcon ? css`
      animation: ${spinIconAnimation} 0.3s linear;
    ` : css`
      animation: ${spinIconAnimation} 0.3s linear;
    `)}
   }
`;

export const OnFlowPicture = styled.img`
  max-height: 550px;

  @media (max-width: 1035px) {
    display: none;
  }
`;

export const Content = styled.div`
  background: url(${DashBackground}) no-repeat;
  background-size: 100% 100%;
  background-color: #553285;
  background-position: 0% 0%;

  overflow: hidden;

  max-width: 100vw;
  margin-top: -100px;

  display: flex;

  @media (max-width: 730px) {
    background: 0;
    background-color: #553285;
  }
`;

export const CourseRowLabel = styled.h3`
  color: white;

  font-weight: 400;
  padding-left: 6vw;
  margin-bottom: 16px;

  @media (max-width: 730px) {
    padding-left: 4vw;
  }
`;

export const CourseRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-left: 6vw;

  @media (max-width: 730px) {
    padding-left: 4vw;
  }
`;

interface FilterProps {
  animatedMaxWidth: number;
  animatedMarginRight: number;
}

export const FilterContentContainer = styled.div<FilterProps>`
  background-color: transparent;

  /* margin-right: ${(props) => props.animatedMarginRight}px; */
  max-width: ${(props) => props.animatedMaxWidth}px;

  transition: 0.5s;
`;

interface FilterContentProps {
  opacity: number;
}

export const FilterContent = styled.div<FilterContentProps>`
  opacity: ${(props) => props.opacity};
  transition: opacity 0.45s ease-in;

  padding: 32px;

  width: 100%;
  flex: 1;

  display: flex;
`;

export const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: #fff;
    margin-bottom: 24px;
  }

  > h3:not(:first-child) {
    margin-top: 56px;
  }

  > svg {
    align-self: flex-end;
    margin-bottom: -32px;
    margin-left: auto;

    color: #fff;
  }

  width: 100%;
`;

export const Filter = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;

  span {
    margin-left: 8px;
    font-size: 22px;
    margin-bottom: 4px;
    color: #fff;
  }
`;

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;
`;
