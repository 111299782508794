import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import {
  FiSearch, FiFilter, FiChevronRight,
} from 'react-icons/fi';

import api from '../../services/api';

import {
  languageCourseList,
  programmingCourseList,
  investmentCourseList,
} from './Courses';

import DashImage from '../../assets/front-image2.svg';

import MyCheckbox from '../../components/MyCheckbox';
import CourseCard from '../../components/CourseCard';
import LoadingCourseCard from '../../components/LoadingCourseCard';

import { Course } from '../../interfaces';
import shuffleArray from '../../utils/shuffle';

import {
  Container,
  Header,
  SearchBarContainer,
  SearchBar,
  FilterContainer,
  OnFlowPicture,
  Content,
  CourseRow,
  CourseRowLabel,
  FilterContentContainer,
  FilterContent,
  FilterColumn,
  Filter,
  CourseContent,
} from './styles';

interface FilterValue {
  name: string;
  checked: boolean;
}

const Dashboard: React.FC = () => {
  const [courseTitle, setCourseTitle] = useState('');
  const [queryForLowestPrice, setQueryForLowestPrice] = useState('');

  const [filterOpen, setFilterOpen] = useState(false);
  const [courses, setCourses] = useState<Course[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [programmingFilters, setProgrammingFilters] = useState<FilterValue[]>([]);
  const [languageFilters, setLanguageFilters] = useState<FilterValue[]>([]);
  const [investmentFilters, setInvestmentFilters] = useState<FilterValue[]>([]);

  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    setProgrammingFilters(programmingCourseList);
    setLanguageFilters(languageCourseList);
    setInvestmentFilters(investmentCourseList);

    async function getCourses() {
      const response = await api.get('/courses/');

      shuffleArray(response.data);
      const homePage = response.data.slice(0, 14);

      setCourses(homePage);

      setIsLoading(false);
    }

    getCourses();
  }, []);

  useEffect(() => {
    async function getCourses(queryCoursesString: string) {
      const response = await api.get('/courses/', {
        params: {
          category__name__in: queryCoursesString,
          name__contains: courseTitle,
          ordering: queryForLowestPrice,
        },
      });

      setCourses(response.data);
    }

    if (filters.length) {
      const queryCoursesString = filters.reduce((accumulator, currentValue) => {
        return accumulator.concat(',', currentValue);
      });

      getCourses(queryCoursesString);
    } else {
      getCourses('');
    }
  }, [filters, courseTitle, queryForLowestPrice]);

  const handleChangeLowerPrice = useCallback(() => {
    if (queryForLowestPrice.length) {
      setQueryForLowestPrice('');
    } else {
      setQueryForLowestPrice('price');
    }
  }, [queryForLowestPrice]);

  const handleCheckProgramming = useCallback((event, index) => {
    const newProgrammingFilters = programmingFilters.map((course, courseIndex) => {
      if (index === courseIndex) {
        if (course.checked) {
          setFilters((state) => state.filter((courseName) => courseName !== course.name));
        } else {
          setFilters((state) => [...state, programmingFilters[index].name]);
        }
        course.checked = !course.checked;
      }

      return course;
    });

    setProgrammingFilters(newProgrammingFilters);
  }, [programmingFilters]);

  const handleCheckLanguages = useCallback((event, index) => {
    const newLanguagesFilters = languageFilters.map((course, courseIndex) => {
      if (index === courseIndex) {
        if (course.checked) {
          setFilters((state) => state.filter((courseName) => courseName !== course.name));
        } else {
          setFilters((state) => [...state, languageFilters[index].name]);
        }
        course.checked = !course.checked;
      }

      return course;
    });

    setLanguageFilters(newLanguagesFilters);
  }, [languageFilters]);

  const handleCheckInvestment = useCallback((event, index) => {
    const newInvestmentFilters = investmentFilters.map((course, courseIndex) => {
      if (index === courseIndex) {
        if (course.checked) {
          setFilters((state) => state.filter((courseName) => courseName !== course.name));
        } else {
          setFilters((state) => [...state, investmentFilters[index].name]);
        }
        course.checked = !course.checked;
      }

      return course;
    });

    setLanguageFilters(newInvestmentFilters);
  }, [investmentFilters]);

  const toggleFilters = useCallback(() => {
    setFilterOpen(!filterOpen);
  }, [filterOpen]);

  const handleChangeSearch = useCallback((event) => {
    setCourseTitle(event.target.value);
  }, []);

  const labelText = useMemo(() => {
    if (isLoading) {
      return 'Buscando cursos...';
    }
    return 'Exibindo os cursos encontrados que mais se adequam a você...';
  }, [isLoading]);

  return (
    <Container>
      <Header>
        <SearchBarContainer>
          <h1>onflow</h1>
          <SearchBar>
            <input
              placeholder="O que você deseja aprender?"
              onChange={handleChangeSearch}
              value={courseTitle}
            />
            <button
              type="button"
            >
              <FiSearch size={22} />
            </button>
          </SearchBar>
          <FilterContainer
            type="button"
            onClick={toggleFilters}
            spinIcon={!!filterOpen}
          >
            {
              filterOpen ? (
                <FiChevronRight size={22} />
              )
                : (
                  <FiFilter size={22} />
                )
            }
            <p>Filtrar busca</p>
          </FilterContainer>
        </SearchBarContainer>
        <OnFlowPicture src={DashImage} />
        {/* <OnFlowPicture /> */}
      </Header>

      <Content>
        <FilterContentContainer
          animatedMaxWidth={filterOpen ? 10000 : 0}
          animatedMarginRight={filterOpen ? 70 : 16}
        >
          <FilterContent
            opacity={filterOpen ? 1 : 0}
          >
            <FilterColumn>
              <h3>Filtros Adicionais</h3>

              <Filter>
                <MyCheckbox
                  checked={!!queryForLowestPrice}
                  onChange={handleChangeLowerPrice}
                />
                <span>Menor preço</span>
              </Filter>

              <h3>Programação</h3>

              {
                programmingFilters.map((course, index) => (
                  <Filter key={course.name}>
                    <MyCheckbox
                      checked={course.checked}
                      onChange={(event) => handleCheckProgramming(event, index)}
                    />
                    <span>{course.name}</span>
                  </Filter>
                ))
              }

              <h3>Línguas</h3>

              {
                languageFilters.map((course, index) => (
                  <Filter key={course.name}>
                    <MyCheckbox
                      checked={course.checked}
                      onChange={(event) => handleCheckLanguages(event, index)}
                    />
                    <span>{course.name}</span>
                  </Filter>
                ))
              }

              <h3>Investimento</h3>

              {
                investmentFilters.map((course, index) => (
                  <Filter key={course.name}>
                    <MyCheckbox
                      checked={course.checked}
                      onChange={(event) => handleCheckInvestment(event, index)}
                    />
                    <span>{course.name}</span>
                  </Filter>
                ))
              }
            </FilterColumn>
          </FilterContent>

        </FilterContentContainer>

        <CourseContent>
          <CourseRowLabel>{labelText}</CourseRowLabel>
          <CourseRow>
            {
              isLoading ? (
                <>
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                  <LoadingCourseCard />
                </>
              )
                : (
                  courses.map((course) => (
                    <CourseCard
                      key={course.id}
                      course={course}
                    />
                  ))
                )
            }

          </CourseRow>
        </CourseContent>
      </Content>
    </Container>
  );
};

export default Dashboard;
