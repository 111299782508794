import React from 'react';

import {
  Container,
  CourseInfos,
  LeftInfos,
  RightInfos,
  Title,
} from './styles';

const CourseCard: React.FC = () => {
  return (
    <Container>
      <Title className="shimmer-effect" />
      <p className="shimmer-effect" />
      <CourseInfos>
        <LeftInfos className="shimmer-effect">
          <div>
            <p />
          </div>
          <div>
            <p />
          </div>
        </LeftInfos>
        <RightInfos className="shimmer-effect">
          <div />
          <p />
        </RightInfos>
      </CourseInfos>
    </Container>
  );
};

export default CourseCard;
