import React, { useMemo } from 'react';
import { FiStar } from 'react-icons/fi';
import { MdLanguage } from 'react-icons/md';
import NumberFormat from 'react-number-format';

import { Course } from '../../interfaces';

import {
  Container,
  CourseInfos,
  LeftInfos,
} from './styles';

interface CourseProps {
  course: Course;
}

const CourseCard: React.FC<CourseProps> = ({ course }) => {
  const priceLabel = useMemo(() => {
    if (Number(course.price)) {
      return 'investimento de';
    }
    return '';
  }, [course]);

  return (
    <Container
      href={course.url}
      target="_blank"
    >
      <h1>{course.platform.name}</h1>
      <p>{course.name}</p>
      <CourseInfos>
        <LeftInfos>
          <div>
            <MdLanguage size={26} />
            <p>{course.language}</p>
          </div>
          <div>
            <FiStar size={26} />
            <p>{Number(course.rating) ? course.rating : 'Avaliações Indisponíveis'}</p>
          </div>
        </LeftInfos>
        <div>
          <p>{priceLabel}</p>
          {
            Number(course.price) ? (
              <NumberFormat
                value={Number(course.price)}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale
                decimalScale={2}
                prefix="R$ "
                renderText={(value) => <h4>{value}</h4>}
              />
            )
              : (
                <h5>GRATIS</h5>
              )

          }
        </div>
      </CourseInfos>
    </Container>
  );
};

export default CourseCard;
